import React, { useState, useEffect } from "react";
import { 
  makeStyles ,
  withStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput as MuiOutlinedInput,} from "@material-ui/core";
import { Link ,useHistory} from "react-router-dom";
import { motion } from "framer-motion";
import ExplicitIcon from '@material-ui/icons/Explicit';
import { ResponsiveBar } from '@nivo/bar'

const useStyles = makeStyles((theme) => ({
  primary: {
    width:"100%",
    display: "flex",
    flexDirection: "column",
    padding:"3vh",
    minHeight:"94vh",
    height:"100%",
    flex:1,
    flexGrow:1,
    overflow:"hidden",
    // backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png')",
    // content: "",
    justifyContent:"flex-start",
    fontFamily:"Source",
    backgroundColor: "#d5d6d8"
  },
  tableWrapper:{
    display:"flex",
    width:"95vw",
    flexDirection:"column",
    zIndex:3
  },
  header:{
    marginTop:16,
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"center",
    fontSize:"1.5rem"
  },
  row:{
    margin:"8px 0px",
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"center",
    fontSize:"1.3rem",
    borderBottom:"1px solid #1C6EA488"
  },
  table:{
    display:"flex",
    flexDirection:"column",
    margin:"8px 0px",
  },
  tableCell:{
    width:"15%",
    transition: "all .2s ease-in-out",
    textAlign:"left",
    "&:hover": {
      cursor: "pointer",
    },
  },
  select: {
    color: "#4f2202",
    padding:"12px !important"
  },
  icon: {
    color: "#4f2202",
  },

}));


export default function DevReports (props) {
  const {setSelectedUser,loginStatus,token,setToken,setEnquiryData} = props
  const classes = useStyles();
  const history = useHistory();
  const [data , setData] = useState([
    {
      line:"Line 1",
      targetPcs:1200,
      targetValue:90000,
      actualPcs:1200,
      actualValue:88000,
      details:[
        {
          worker:"Operator",
          number:12,
          rate:500,
          hours:8
        },
        {
          worker:"Helper",
          number:4,
          rate:600,
          hours:8
        },
        {
          worker:"QA",
          number:2,
          rate:800,
          hours:8
        },
        {
          worker:"Line Manager",
          number:1,
          rate:1000,
          hours:8
        }
      ]
    },
    {
      line:"Line 2",
      targetPcs:1000,
      targetValue:70000,
      actualPcs:1100,
      actualValue:77000,
      details:[
        {
          worker:"Operator",
          number:12,
          rate:18,
          hours:8
        },
        {
          worker:"Helper",
          number:4,
          rate:28,
          hours:8
        },
        {
          worker:"QA",
          number:2,
          rate:40,
          hours:8
        },
        {
          worker:"Line Manager",
          number:1,
          rate:40,
          hours:8
        }
      ]
    },
    {
      line:"Line 3",
      targetPcs:500,
      targetValue:30000,
      actualPcs:490,
      actualValue:29000,
      details:[
        {
          worker:"Operator",
          number:5,
          rate:18,
          hours:8
        },
        {
          worker:"Helper",
          number:2,
          rate:28,
          hours:8
        },
        {
          worker:"Line Manager",
          number:1,
          rate:40,
          hours:8
        }
      ]
    },
    {
      line:"Line 4",
      targetPcs:800,
      targetValue:60000,
      actualPcs:690,
      actualValue:58000,
      details:[
        {
          worker:"Operator",
          number:5,
          rate:18,
          hours:8
        },
        {
          worker:"Helper",
          number:2,
          rate:28,
          hours:8
        },
        {
          worker:"Line Manager",
          number:1,
          rate:40,
          hours:8
        }
      ]
    },
    {
      line:"Line 5",
      targetPcs:1200,
      targetValue:90000,
      actualPcs:850,
      actualValue:72000,
      details:[
        {
          worker:"Operator",
          number:5,
          rate:18,
          hours:8
        },
        {
          worker:"helper",
          number:2,
          rate:28,
          hours:8
        },
        {
          worker:"Line Manager",
          number:1,
          rate:40,
          hours:8
        }
      ]
    },
    {
      line:"Line 6",
      targetPcs:500,
      targetValue:30000,
      actualPcs:490,
      actualValue:29000,
      details:[
        {
          worker:"Operator",
          number:5,
          rate:18,
          hours:8
        },
        {
          worker:"helper",
          number:2,
          rate:28,
          hours:8
        },
        {
          worker:"Line Manager",
          number:1,
          rate:40,
          hours:8
        }
      ]
    }
  ]);
  const [dateRange,setDateRange] = useState({time:"day",depth:"today"})
  const [exit , setExit] = useState(false);
  const [exitx , setExitx] = useState(false);
  const [showClients , setShowClients] = useState(false);
  const [client, setClient] = React.useState(0);
  const [clients, setClients] = React.useState([]);
  const [project, setProject] = React.useState("");
  const [projects, setProjects] = React.useState([]);
  const [pcsVal, setPcsVal] = React.useState("val");



  useEffect(() => {
    // getData()
    if(loginStatus<=0){
      history.push("/")
    }
  }, []);

  const container = {
    hidden: { opacity: 0 ,y:-20},
    show: {
      opacity: 1,
      y:0,
      transition: {
        staggerChildren: 0.05
      }
    },
    exit: { opacity: 0 ,y:-20,
      transition: {
      staggerChildren: 0.05
    }},
  }
  const containerx = {
    hidden: { opacity: 0 ,x:-20},
    show: {
      opacity: 1,
      x:0,
      transition: {
        delay:.5,
        staggerChildren: .1,
        delayChildren:.5
      }
    },
    exit: {
      opacity: 0,
      x:-20,
      transition: {
        delay:.5,
        staggerChildren: .1,
        delayChildren:.5
      }
    }
  }
  
  const itemx = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }
  const sortData=(key)=>{
    setData([])
    var temp = JSON.parse(JSON.stringify(data))
    temp.sort((a,b) => a[key] - b[key]).reverse()
    setData(temp)
    setExitx(false)
  }

  const totalify = () =>{
    let totalObj = { targetCost:0,targetPcs:0,actualCost:0,actualPcs:0 }
    data.forEach((item,index)=>{
      totalObj.targetCost=totalObj.targetCost+item.targetValue
      totalObj.targetPcs=totalObj.targetPcs+item.targetPcs
      totalObj.actualCost=totalObj.actualCost+item.actualValue
      totalObj.actualPcs=totalObj.actualPcs+item.actualPcs
    })
    return totalObj
  }

  return (
    <motion.div 
      className={classes.primary}
      >
        <motion.div
        initial={{
          backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png')",
          content: "",
          height: "200%",
          left: "-50%",
          opacity: .23,
          zIndex:2,
          position: "fixed",
          top: "-100%",
          width: "200%",
        }}
        animate={{
          translate:[("0","0"),("-5%", "-10%"),("-5%", "5%"),("7%", "-5%"),("-5%", "5%"),("5%", "0%"),("-0%", "5%"),("3%", "5%"),("0%", "0%")]
        }}
        transition={{
          duration: .2,
          ease: "linear",
          repeat: Infinity,
          repeatDelay: 0
        }}
      >

      </motion.div>
      <motion.div style={{display:"flex",flexDirection:"row",width:"90%"}}>
        <motion.div 
          variants={container}
          initial="hidden"
          animate={exit?"exit":"show"}
          onClick={()=>{
            setProject("")
            setClient("")
            setClients([])
            setShowClients(false)
            // getData(dateRange.time,dateRange.depth,0,0)
          }}
          style={{fontSize:"2.8rem",color:"#000000",display:"flex",flexDirection:"row",width:"40%",zIndex:3}}>
          {[..."Dashboard"].map((item, index) => (
                  <motion.div
                    variants={itemx}
                    whileHover={{ scale: 1.02 }}
                  >
                    {item}
                  </motion.div>
                ))}
        </motion.div>
      </motion.div>
      <div className={classes.tableWrapper}>
        {pcsVal=="val"?
        <motion.div 
          variants={container}
          initial="hidden"
          animate={exit?"exit":"show"}
          className={classes.header}>
          <div style={{width:"95vw",height:"40vh"}}>
             <ResponsiveBar
                data={data}
                keys={[
                    'targetValue',
                    'actualValue',
                ]}
                indexBy="line"
                groupMode="grouped"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.4}
                innerPadding={5}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={["#939599","#007ec4"]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    fontSize:30,
                    legend: 'Line',
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Amount',
                    legendPosition: 'middle',
                    legendOffset: -50
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
            />
           </div>
        </motion.div>:<motion.div 
          variants={container}
          initial="hidden"
          animate={exit?"exit":"show"}
          className={classes.header}>
          <div style={{width:"95vw",height:"40vh"}}>
             <ResponsiveBar
                data={data}
                keys={[
                    'targetPcs',
                    'actualPcs',
                ]}
                indexBy="line"
                groupMode="grouped"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.4}
                innerPadding={5}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={["#939599","#00649d"]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    fontSize:30,
                    legend: 'Line',
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Amount',
                    legendPosition: 'middle',
                    legendOffset: -50
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
            />
           </div>
        </motion.div>}
      </div>
      <div className={classes.tableWrapper}>
        <motion.div 
          variants={container}
          initial="hidden"
          animate={exit?"exit":"show"}
          className={classes.header}>
          <div style={{width:"10%"}} 
          onClick={()=>{
            setExitx(true)
            setTimeout(()=>{sortData("line")}, 800);
            }}>
            Line
          </div>
          <div style={{width:"15%",textAlign:"right",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{{
                sortData("COMPANY_NAME")
                setPcsVal("pcs")
              }}, 800);
              }}>
             Target Pcs
          </div>
          <div style={{width:"15%",textAlign:"right",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{{
                sortData("COMPANY_NAME")
                setPcsVal("val")
              }}, 800);
              }}>
             Target Val
          </div>
          <div style={{width:"15%",textAlign:"right",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{sortData("ADDED_ON")}, 500);
              }}>
             Per Pcs Target
          </div>
          <div style={{width:"15%",textAlign:"right",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{{
                sortData("COMPANY_NAME")
                setPcsVal("pcs")
              }}, 800);
              }}>
            Actual Pcs
          </div>
          <div style={{width:"15%",textAlign:"right",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{{
                sortData("COMPANY_NAME")
                setPcsVal("val")
              }}, 800);
              }}>
            Actual Value
          </div>
          <div style={{width:"15%",textAlign:"right",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{sortData("pending")}, 500);
              }}>
            Actual Per Pcs
          </div>
        </motion.div>
        <motion.div 
          variants={containerx}
          initial="hidden"
          animate={exitx?"exit":"show"}
          className={classes.table}>
          {data.map((item, index) => (
                <motion.div
                  variants={itemx}
                  className={classes.row}
                >
                  <div 
                    onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                    className={classes.tableCell}
                    style={{width:"10%",fontSize:"1.7rem"}}>
                    {item.line}
                  </div>
                  <motion.div
                   className={classes.tableCell} style={{color:"#48494a"}}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                    {item.targetPcs}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#48494a"}}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {item.targetValue}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#48494a"}}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {Math.round((item.targetValue/item.targetPcs*100))/100}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#00649d"}}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {item.actualPcs}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#00649d"}}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {item.actualValue}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#00649d"}}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                     {Math.round((item.actualValue/item.actualPcs*100))/100}
                    </motion.div>
                  </motion.div>
                </motion.div>
              ))}
                  <motion.div
                  variants={itemx}
                  className={classes.row}
                >
                  <div 
                    className={classes.tableCell}
                    style={{width:"10%",fontSize:"1.7rem"}}>
                    {"Total"}
                  </div>
                  <motion.div
                   className={classes.tableCell} style={{color:"#48494a"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                    {totalify().targetPcs}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#48494a"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {totalify().targetCost}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#48494a"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                       {Math.round((totalify().targetCost/totalify().targetPcs)*100)/100}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#00649d"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {totalify().actualPcs}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#00649d"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {totalify().actualCost}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#00649d"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                     {Math.round((totalify().actualCost/totalify().actualPcs)*100)/100}
                    </motion.div>
                  </motion.div>
                </motion.div>
        </motion.div>
      </div>
    </motion.div>
  )
}
