import React, { useState, useEffect } from "react";
import { makeStyles ,withStyles,Checkbox} from "@material-ui/core";
import { Link ,useHistory} from "react-router-dom";
import { motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import { ResponsiveBar } from '@nivo/bar'

const GreenCheckbox = withStyles({
  root: {
    color: "#c8102eAA",
    width: 10,
    height: 10,
    '&$checked': {
      color: "#7eb293AA",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({

  primary: {
    width:"100%",
    display: "flex",
    flexDirection: "column",
    padding:"3vh",
    minHeight:"94vh",
    height:"100%",
    flex:1,
    flexGrow:1,
    overflow:"hidden",
    // backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png')",
    // content: "",
    justifyContent:"flex-start",
    fontFamily:"Source",
    backgroundColor: "#d5d6d8"
  },
left:{
    display:"flex",
    width:"100vw",
    flexDirection:"column",
    zIndex:3
  },
  right:{
    display:"flex",
    width:"55vw",
    flexDirection:"column",
    zIndex:3
  },
  imageCard:{
    margin:"5px",
    width:"21vw",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    fontSize:"1.3rem",
    borderRadius:8
  },
  button:{
    alignItems:"flex-start",
    fontSize:"1.3rem",
    marginTop:10,
    display:"flex",
    justifyContent:"flex-start",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.03)",
      cursor: "pointer",
    }
  },
  row:{
    margin:"8px 0px",
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"center",
    fontSize:"1.3rem",
    borderBottom:"1px solid #1C6EA488"
  },
  table:{
    display:"flex",
    flexDirection:"column",
    margin:"8px 0px",
  },
  tableCell:{
    width:"20%",
    transition: "all .2s ease-in-out",
    textAlign:"left",
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableWrapper:{
    display:"flex",
    width:"95vw",
    flexDirection:"column",
    zIndex:3
  },
  header:{
    marginTop:16,
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"center",
    fontSize:"1.5rem"
  },
}));




export default function Individual (props) {
  const {enquiryData,setEnquiryData,token,userID} = props
  const classes = useStyles();
  const history = useHistory();
  const [exit , setExit] = useState(false);

  const container = {
    hidden: { opacity: 0 ,y:-20},
    show: {
      opacity: 1,
      y:0,
      transition: {
        staggerChildren: 0.05
      }
    },
    exit: { opacity: 0 ,y:-20,
      transition: {
      staggerChildren: 0.05
    }},
  }
  
  const itemx = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }
  const containerx = {
    hidden: { opacity: 0 ,x:-20},
    show: {
      opacity: 1,
      x:0,
      transition: {
        delay:.5,
        staggerChildren: .1,
        delayChildren:.5
      }
    },
    exit: {
      opacity: 0,
      x:-20,
      transition: {
        delay:.5,
        staggerChildren: .1,
        delayChildren:.5
      }
    }
  }

  const totalify = () =>{
    let totalVal = 0
    enquiryData.details.forEach((item,index)=>{
     totalVal=totalVal+(item.number*item.hours*item.rate)
    })
    return totalVal
  }


  return (
    <motion.div 
      className={classes.primary}
      >
          <Toaster
            position="bottom-right"
            toastOptions={{
              // Define default options
              className: "",
             
              style: {
                color: " #FFFFFF",
                background: "#4f2202",
              },
              success: {
                duration: 4000,
                style: {
                  color: " #666666",
                  background: "#FFFFFFDD",
                },
              },
              error: {
                duration: 2000,
                style: {
                  color: " #666666",
                  background: "#FFFFFFDD",
                },
              },


            }}
          />
      <motion.div
        initial={{
          backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png')",
          content: "",
          height: "200%",
          left: "-50%",
          opacity: .23,
          zIndex:2,
          position: "fixed",
          top: "-100%",
          width: "200%",
        }}
        animate={{
          translate:[("0","0"),("-5%", "-10%"),("-5%", "5%"),("7%", "-5%"),("-5%", "5%"),("5%", "0%"),("-0%", "5%"),("3%", "5%"),("0%", "0%")]
        }}
        transition={{
          duration: .2,
          ease: "linear",
          repeat: Infinity,
          repeatDelay: 0
        }}
      >

      </motion.div>
      <div className={classes.left}>
        <motion.div 
          variants={container}
          initial="hidden"
          animate={exit?"exit":"show"}
          style={{fontSize:"3rem",fontWeight:"vold",color:"#00649d",display:"flex",flexDirection:"row",width:"100%",zIndex:3}}>
          {[...enquiryData.line.toString()].map((item, index) => (
                  <motion.div
                    variants={itemx}
                    whileHover={{ scale: 1.02 }}
                  >
                    {item}
                  </motion.div>
                ))}
        </motion.div>
      </div>
      <div className={classes.tableWrapper}>
        <motion.div 
          variants={container}
          initial="hidden"
          animate={exit?"exit":"show"}
          className={classes.header}>
          <div style={{width:"20%"}} >
            Role
          </div>
          <div style={{width:"20%",textAlign:"right",borderRadius:8}}>
             Quantity
          </div>
          <div style={{width:"20%",textAlign:"right",borderRadius:8}}>
             Rate
          </div>
          <div style={{width:"20%",textAlign:"right",borderRadius:8}}>
             Hours
          </div>
          <div style={{width:"20%",textAlign:"right",borderRadius:8}}>
             Total
          </div>
        </motion.div>
        <motion.div 
          variants={containerx}
          initial="hidden"
          animate={exit?"exit":"show"}
          className={classes.table}>
          {enquiryData.details.map((item, index) => (
                <motion.div
                  variants={itemx}
                  className={classes.row}
                >
                  <div 
                    className={classes.tableCell}
                    style={{width:"20%",fontSize:"1.7rem"}}>
                    {item.worker}
                  </div>
                  <motion.div
                   className={classes.tableCell} style={{color:"#48494a"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                    {item.number}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#48494a"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {item.rate}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#48494a"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {item.hours}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell} style={{color:"#48494a"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {item.hours*item.number*item.rate}
                    </motion.div>
                  </motion.div>
                </motion.div>
              ))}
        </motion.div>
        <motion.div
                  variants={itemx}
                  className={classes.row}
                >
                  <div 
                    className={classes.tableCell}
                    style={{width:"20%",fontSize:"1.7rem"}}>
                    {"Actual Cost"}
                  </div>
                  <motion.div className={classes.tableCell} style={{color:"#48494a",width:"80%"}}>
                    <motion.div 
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {totalify()}
                    </motion.div>
                  </motion.div>
                </motion.div>
      </div>
    </motion.div>
  )
}
